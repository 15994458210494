<template>
  <div class="h-min-content xl:w-5/6 sm:w-full">
    <div v-if="loading" class="spinner">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>

    <div v-if="loading === false">
      <div
        v-if="complaint"
        class="w-full md:w-10/12 mb-4 p-6 bg-white rounded-2xl shadow-lg mt-5"
      >
        <div class="w-11/12 grid grid-cols-2 gap-2">
          <BaseInput
            type="text"
            label="Fecha de actualización"
            v-model="complaint.fecha"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <!-- queja -->
      <div
        class="w-full md:w-10/12 mb-4 bg-white rounded-xl shadow-lg text-gray-600"
        :v-if="complaint"
      >
        <div
          class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
          @click="toggleAccordion2"
          style="border-bottom: gray solid 1px"
        >
          <p class="text-dark text-left"><b>Detalle de la queja</b></p>
          <span
            class="h-5 w-5 m-2 flex justify-center items-center transition"
            :class="{ 'transform rotate-180': accordionOpen2 }"
          >
            <img src="@/assets/icons/arrow-down.svg" alt="" />
          </span>
        </div>
        <div v-if="accordionOpen2 && complaint" class="p-3">
          <div v-if="complaint && customer" class="w-full">
            <ComplaintDetailHeaderCard
              :radicado="complaint.codigo_queja"
              detail="true"
              :status="complaint.estado_cod"
            />
            <h2 class="my-5 text-xl text-left text-blue">
              <b>Datos de contacto</b>
            </h2>
            <div class="grid grid-cols-2 gap-4 w-full">
              <BaseRadio
                ref="personType"
                name="personType"
                id="personType"
                label="Tipo de persona"
                :radioOptions="formOptions.optionsPersonType"
                v-model="customer.person_type"
                disabled="true"
              />
            </div>
            <CustomerNatural
              v-if="customer && customer.person_type == 1"
              :customer="customer"
            />
            <CustomerLegal
              v-if="customer && customer.person_type == 2"
              :customer="customer"
            />
          </div>
          <Complaint
            v-if="complaint && Object.keys(complaint).length > 0"
            :complaint="complaint"
          />
          <hr />
          <div class="w-full my-8">
            <p
              v-if="complaint.desistimiento_queja == 1"
              class="text-left rounded-xl"
            >
              Ha desistido de la interposición de su queja.
            </p>
            <p
              v-if="
                (complaint.desistimiento_queja == 2 ||
                  complaint.desistimiento_queja == null) &&
                complaint.estado_cod == 'Recibida' &&
                !complaint.vencido_por_tiempo
              "
              class="text-left rounded-xl"
            >
              La queja está siendo revisada por la entidad vigilada y le enviará una
              respuesta de manera directa a usted.
            </p>
            <p v-if="complaint.vencido_por_tiempo" class="text-left rounded-xl">
              Han trascurrido dos meses después de recibida la respuesta por
              parte de la entidad. En caso de que persista su inconformidad
              puede radicar una queja nueva.
            </p>
          </div>
        </div>
      </div>

      <AnnexesManagement 
        class="w-full md:w-10/12"
        :references="general_references"
        :complaint="complaint" 
        v-if="customer && complaint" 
      />
      
      <AnswerManagement
        class="w-full md:w-10/12"
        :complaint="complaint"
        v-if="customer && complaint"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import Complaint from "@/components/Dashboard/Complaints/Complaint";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";
import ComplaintDetailHeaderCard from "@/components/Dashboard/Complaints/ComplaintDetailHeaderCard";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import AnswerManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnswerManagement";
export default {
  components: {
    Complaint,
    ComplaintDetailHeaderCard,
    CustomerLegal,
    CustomerNatural,
    AnswerManagement,
    AnnexesManagement,
  },
  async created() {
    const { complaint_code, updated_at } = this.$route.params;
    this.loading = true;
    let { error, data } = await this.$api.getBigqueryComplaint(
      complaint_code,
      `?fecha_actualizada=${updated_at}`
    );

    this.loading = false;

    if (error)
      this.showToast(
        "error",
        "Error consultando detalle histórico de la queja."
      );

    if (data) {
      const complaint = data?.data;
      const archivos = data?.archivos ? data?.archivos : [];
      const numero_id_CF = complaint.numero_id_CF;
      const tipo_id_CF = complaint.tipo_id_CF;

      this.complaint = {
        ...complaint,
        codigo_pais: this.$store.state.form.optionsCountries.find(
          (item) => item.id === complaint.codigo_pais
        )?.name,
        producto_cod: complaint.producto_nombre,
        fecha: complaint.fecha_creacion_en_auditoria
          ? format(
              new Date(complaint.fecha_creacion_en_auditoria),
              "dd/MM/yyyy hh:mm a"
            )
          : "",
        lgbtiq: complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No": "",
        specialCondition:
            complaint.condicion_especial === "No aplica"?
              2: complaint.condicion_especial == null ? 
              null:
              1,
        es_apoderado: complaint.apoderado_nombre ? 2 : 1,
        abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
        archivos_apoderado: archivos.filter(
          (item) => item.reference === "attorney"
        ),
        expres: complaint.queja_expres == "SI" ? "Sí" : "No",
        tutela_nombre:
          complaint.tutela_nombre === 1
            ? "La queja o reclamo tiene asociada una acción de tutela"
            : "La queja o reclamo tiene NO asociada una acción de tutela",
        created_at: updated_at,
        documentacion_final: complaint.documentacion_final ? "Sí" : "No",
      };

      this.loading = true;
      let identificationType_id = 1; // Por defecto 1
      const objIdentificationType =
        this.$store.state.form.optionsIdentificationType?.filter(
          (identificationType) => identificationType.name == tipo_id_CF
        );
      if (objIdentificationType.length > 0) {
        identificationType_id = objIdentificationType[0].id;
      }
      // RQ5 - si la queja está cerrada se traen desde Bigquery
      let result;
      if (complaint.user_history_id) {
        result = await this.$api.getQueryPersonHistory(
          `?identification_number=${numero_id_CF}&user_history=${complaint.user_history_id}`
        );
      } else {
        result = await this.$api.getQueryPerson(
          `?identification_number=${numero_id_CF}&identification_type=${identificationType_id}`
        );
      }

      this.loading = false;

      if (result.error) {
        this.showToast(
          "error",
          "Error recuperando información del usuario asociado a la queja"
        );
        return;
      }

      if (result.data) {
        if (result.data.results.length === 0) {
          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            person_type: complaint.tipo_persona === "Natural" ? 1 : 2,
            first_name: this.complaint.nombres,
            last_name: null,
          };
        } else if (!complaint.user_history_id) {
          const user = result.data.results[0];

          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            birth_date: user.birth_date,
            person_type: user.person_type,
            first_name: user.user_id.first_name,
            last_name: user.user_id.last_name,
            business_name: user.user_id?.business_name,
            phone: user.user_id.phone,
            email: user.user_id.email,
            departamento_cod: user.user_id?.departamento_cod,
            municipio_cod: user.user_id?.municipio_cod,
            address: user.user_id?.address,
          };
        } else if (complaint.user_history_id) {
          const user = result.data.results[0];
          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            birth_date: user.birth_date,
            person_type: complaint.tipo_persona === "Natural" ? 1 : 2,
            first_name: user.first_name,
            last_name: user.last_name,
            business_name: user?.business_name,
            phone: user.phone,
            email: user.email,
            departamento_cod: user?.departamento_cod,
            municipio_cod: user?.municipio_cod,
            address: user?.address,
          };
        }
      }
    }
  },
  data() {
    return {
      customer: null,
      complaint: null,
      accordionList: [
        {
          title:
            "¿Cuál es el plazo que tiene la entidad vigilada para responder?",
          body: "La entidad cuenta con 15 días hábiles a la recepción de la queja, para dar respuesta. En caso en que la entidad requiera de un mayor plazo para atender la queja podrá informar al consumidor para obtener un tiempo adicional para responder.",
        },
        {
          title: "¿Qué pasa si el consumidor desiste de la queja?",
          body: "La consecuencia del desistimiento es que la queja será cerrada y recibirá un correo electrónico en el que se informará la modificación del estado de la misma.  Si Usted desiste no podrá continuar con el trámite. Si persisten las inconsistencias después de pasado un tiempo deberá radicar una nueva queja.",
        },
      ],
      showModal: false,
      accordionOpen2: false,
      accordionOpen: false,
      loading: false,
      general_references: "!,2,6,7",  // 2:attorney, 6:management, 7:answer
    };
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    toggleAccordion2() {
      this.accordionOpen2 = !this.accordionOpen2;
    },

    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
